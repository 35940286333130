import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
// import AbsurdImage06 from "../components/abusrd-06";
import FishImg from "../images/abusrd_design06.png"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hi people</h1>
    <p>Welcome to the personal website of Atlee Solomon.</p>
    <p>Coming Soon! In the meantime, feel free to check out my other projects.</p>
      <a href="https://telemedicine-template.netlify.app">Telemedicine Template</a>
      <br />
      <a href="https://www.atleebiotech.com">Atlee Biotech, Inc</a>
      <br />
      Please feel free to contact me at <a href="mailto:atleecs@gmail.com">atleecs@gmail.com</a>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      {/*<Image />*/}
      {/*<AbsurdImage06 />*/}
        <img src={FishImg} alt="fish" />
        This is a cool image from <a href="https://absurd.design/">absurd.design</a>
    </div>
    <Link to="/page-2/">Go to page 2</Link>
  </Layout>
)

export default IndexPage
